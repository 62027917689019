import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import { casinoSection as casinoData } from "../../../../lib/data/casinoSection.data";
import { auraGames } from "../../../../lib/data/auraGames.data";
import GamesSearchModal from "../../../components/GamesSearchModal";
import { SlotGames } from "../../../../lib/data/SlotGames.data";
import { KingmakerGames } from "../../../../lib/data/KingmakerGames.data";
import BottomNav from "../../../../containers/BottomNav";

import Filter from "../../../../assets/images/icons/filter.png";
import CloseIcon from "../../../../assets/images/icons/close-icon.webp";

import RouletteCat from "../../../../assets/images/categories/Roulette.webp";
import PopularCat from "../../../../assets/images/categories/Popular.webp";
import NetentCat from "../../../../assets/images/categories/net-ent.webp";
import RedtigerCat from "../../../../assets/images/categories/red-tiger.webp";
import improvedExperienceCat from "../../../../assets/images/categories/improve-experience.webp";
import CardGamesCat from "../../../../assets/images/categories/card-game.webp";
import VirtualCardCat from "../../../../assets/images/categories/virtual-card.webp";
import InstantCat from "../../../../assets/images/categories/instant-game.webp";
import KingmakerCat from "../../../../assets/images/categories/Popular.webp";

import Slide1 from "../../../../assets/images/banner/live-games/banner1.webp";
import Slide2 from "../../../../assets/images/banner/live-games/banner2.webp";
import CasinoFooterContent from "../../../components/FooterContent/CasinoFooterContent";
import Footer from "../../../../containers/Footer";

const Casino = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { banners } = useSelector((state) => state.app);
  const casinoSection = { ...auraGames, ...SlotGames, ...KingmakerGames };
  const [providerName, setProviderName] = useState(null);
  console.log("casinoData", casinoSection);
  const [selectedProvider, setSelectedProvider] = useState({
    id: 1,
    name: "All",
    eventKey: "all"
  });
  const [activeTab, setActiveTab] = useState({
    id: 1,
    name: "Casino",
    key: "popularGames",
    imgSrc: PopularCat
  });
  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [allGamesData, setAllGamesData] = useState(null);

  const providersArray = [
    { id: 1, name: "All", eventKey: "all" },
    { id: 2, name: "Aura", eventKey: "aura" },
    { id: 3, name: "Slots", eventKey: "slots" },
    { id: 4, name: "Kingmaker", eventKey: "kingmaker" }
  ];
  const tabsArray = [
    {
      id: 1,
      name: "Netent Games",
      key: "netenttab",
      imgSrc: NetentCat
    },
    {
      id: 2,
      name: "Redtiger Games",
      key: "redtigertab",
      imgSrc: RedtigerCat
    },
    {
      id: 3,
      name: "Improved Experience",
      key: "improvedExperience",
      imgSrc: improvedExperienceCat
    },
    {
      id: 4,
      name: "Card Games",
      key: "cardGames",
      imgSrc: CardGamesCat
    },
    {
      id: 5,
      name: "Virtual CardGames",
      key: "virtualCardGames",
      imgSrc: VirtualCardCat
    },
    {
      id: 6,
      name: "Instant Games",
      key: "instantGames",
      imgSrc: InstantCat
    },
    {
      id: 7,
      name: "King Maker",
      key: "kingmaker",
      imgSrc: KingmakerCat
    }
  ];
  function scrollToElement(elementId) {
    var targetElement = document.getElementById(elementId);

    if (targetElement) {
      var offsetTop = targetElement.getBoundingClientRect().top;

      var offset = offsetTop - 100;

      window.scrollTo({
        top: window.scrollY + offset,
        behavior: "smooth"
      });
    }
  }
  useEffect(() => {
    filterAllGamesData();
  }, []);
  const manageActiveTab = (provider) => {
    setCustomActiveTab({
      id: 1,
      name: " Casino",
      key: "allGames",
      imgSrc: RouletteCat
    });
    filterAllGamesData(provider.eventKey);
  };

  const getTabActiveClass = (tab) => {
    if (customActiveTab) {
      return tab.key === customActiveTab.key;
    }
    return tab.key === activeTab?.key;
  };
  const filterAllGamesData = (provider = selectedProvider?.eventKey) => {
    let emptyArray = {};
    Object.keys(casinoSection).map((key) => {
      // console.log("key", key);
      if (
        key !== "allGames" &&
        tabsArray.some((s) => s.key === key) &&
        typeof casinoSection[key] !== "string"
      ) {
        emptyArray = { ...emptyArray, ...casinoSection[key] };
      }
    });
    let filteredValues;
    if (provider !== "all") {
      filteredValues = Object.values(emptyArray).filter(
        (f) => f.providerName?.toLowerCase() === provider
      );
    } else {
      filteredValues = Object.values(emptyArray).filter(
        (f) =>
          f.providerName?.toLowerCase() === "aura" ||
          f.providerName?.toLowerCase() === "slots"
      );
    }

    setAllGamesData(filteredValues);
  };

  const settings = {
    dots: false,
    fade: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const handleSelect = (eventKey) => {
    setProviderName(null);
    const provider =
      providersArray.find((p) => p?.eventKey === eventKey) || "all";
    setSelectedProvider(provider);
    manageActiveTab(provider);
  };

  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (isActive) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
    return () => {
      htmlElement.style.overflow = "auto";
    };
  }, [isActive]);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="LiveCasinoSection pb-0">
          {/* <CasinoGamesSection /> */}
          <div className={`casino-games ${isActive ? "games-filter" : ""}`}>
            <div className="games-type">
              <div className="SearchGames">
                <GamesSearchModal />

                <a
                  href="#"
                  className="filter-toggle d-md-none"
                  onClick={toggleClass}
                >
                  <img src={CloseIcon} alt="close" />
                </a>
              </div>

              <div className="game-filter">
                <ul className="categories">
                  <h6>Categories</h6>

                  {tabsArray?.map((tab, index) => {
                    return (
                      <React.Fragment key={index}>
                        {((casinoSection?.[tab?.key] &&
                          Object.values(casinoSection?.[tab?.key]).some(
                            (s) =>
                              s?.providerName?.toLowerCase() ===
                              selectedProvider?.eventKey
                          )) ||
                          selectedProvider?.eventKey === "all" ||
                          tab?.key == "GamesAll") && (
                          <li
                            onClick={() => {
                              setCustomActiveTab(null);
                              if (tab.key === "allGames") {
                                filterAllGamesData();
                              }
                              setActiveTab(tab);
                              scrollToElement("all-images");
                              setProviderName(null);
                            }}
                            // key={index}
                          >
                            <a
                              href="#"
                              className={getTabActiveClass(tab) ? "active" : ""}
                            >
                              <img src={tab?.imgSrc} alt="roulette" />
                              <span>{tab?.name}</span>
                            </a>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>

                <ul className="providers">
                  <h6>Providers</h6>

                  {providersArray.map((p, index) => {
                    return (
                      <li key={index}>
                        <a
                          href="#"
                          onClick={() => {
                            handleSelect(p?.eventKey);
                            setProviderName(p?.name);
                            scrollToElement("all-images");
                          }}
                        >
                          <span>{p?.name}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="BannerSection">
            <div className="bannerSec d-none d-md-block">
              <div className="banners casino live">
                <div className="header-carousel casino">
                  <Slider {...settings}>
                    <div>
                      <div className="banner">
                        <img src={Slide1} alt="first slide" />
                        {/* <div className="banner-content">content 1...</div> */}
                      </div>
                    </div>
                    <div>
                      <div className="banner">
                        <img src={Slide2} alt="second slide" />
                        {/* <div className="banner-content">content 2...</div> */}
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>

            <div className="all-images" id="all-images">
              <di className="games-heading" id="scrollheading">
                <h3> {providerName || Object.values([activeTab?.name])}</h3>
                <a
                  href="#"
                  className="filter-toggle d-md-none"
                  onClick={toggleClass}
                >
                  <img src={Filter} alt="filter" /> Filter
                </a>
              </di>

              <ul className="gamelisting">
                {casinoSection?.[activeTab?.key] &&
                activeTab?.key !== "allGames" &&
                customActiveTab?.key !== "allGames"
                  ? Object.values(casinoSection?.[activeTab?.key]).map(
                      (casino, index) => {
                        return (
                          <React.Fragment key={index}>
                            {(selectedProvider?.eventKey === "all" ||
                              casino?.providerName?.toLowerCase() ===
                                selectedProvider.eventKey) && (
                              <li key={index}>
                                <a href={casino?.redirectUrl}>
                                  <img src={casino?.imgUrl} />

                                  <div className="providerName">
                                    {casino?.providerName}
                                  </div>
                                  <div className="GameName">{casino?.name}</div>
                                </a>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      }
                    )
                  : allGamesData &&
                    allGamesData.map((casino, index) => {
                      return (
                        <li key={index}>
                          <a href={casino?.redirectUrl}>
                            <img src={casino?.imgUrl} />
                            <div className="providerName">
                              {casino?.providerName}
                            </div>
                            <div className="GameName">{casino?.name}</div>
                          </a>
                        </li>
                      );
                    })}
              </ul>
            </div>
            <div>
              <CasinoFooterContent />
              <Footer />
            </div>
          </div>
        </div>
      </main>

      <BottomNav />
    </>
  );
};

export default Casino;
