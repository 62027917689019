import React, { useState } from "react";
import { useSelector } from "react-redux";

import WhatsappIcon1 from "../assets/images/whatsapp.png";
import TelegramIcon from "../assets/images/Telegram.svg";
import InstaIcon from "../assets/images/Instagram.svg";
import Facebook from "../assets/images/facebook-512.svg";
import Calling from "../assets/images/phone-call.png";
import BottomNav from "./BottomNav";

const BeforeLoginFooter = () => {
  const { appDetails } = useSelector((state) => state.app);

  const [isActive, setIsActive] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer">
        <div className="foot-top">
          <div className="foot-logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            </a>
          </div>
        </div>

        <div className="FooterLinksSection">
          <div className="footerLinks">
            <h2>Casino</h2>
            <ul>
              <li>
                <a href="/casino-games">Slots</a>
              </li>
              <li>
                <a href="/livecasino">Live Casino</a>
              </li>
              <li>
                <a href="/casino/ezgevo-blackjack-b">Blackjack</a>
              </li>
              <li>
                <a href="/casino/ezg-italian-roulette">Roulette</a>
              </li>
              <li>
                <a href="/casino/ezg-baccarat">Baccarat</a>
              </li>
            </ul>
          </div>
          <div className="footerLinks">
            <h2>Sports</h2>
            <ul>
              <li>
                <a href="/spmsports/cricket">Exchange</a>
              </li>
              <li>
                <a href="/betby">Sportsbook</a>
              </li>
            </ul>
          </div>
          <div className="footerLinks">
            <h2>Promo</h2>
            <ul>
              <li>
                <a href="#">Affiliates</a>
              </li>
              <li>
                <a href="#">Promotions</a>
              </li>

              <li>
                <a href="/referral">Refer a Friend</a>
              </li>
            </ul>
          </div>
          <div className="footerLinks">
            <h2>Support/Legal</h2>
            <ul>
              <li>
                <a href="/contactuss">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicys">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegames">Responsible Gaming</a>
              </li>
            </ul>
          </div>
          <div className="footerLinks">
            <h2>About Us</h2>
            <ul>
              <li>
                <a href="/fairplays">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrules">Games Rules</a>
              </li>
              <li>
                <a href="/termss">Terms and Conditions</a>
              </li>
            </ul>
          </div>
          <div className="footerLinks">
            <h2>Join Our Community</h2>
            <ul>
              <li>
                <a href={`tel://${appDetails?.PHONE}`}>
                  <img src={Calling} alt="Whatsapp Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={WhatsappIcon1} alt="Whatsapp Icon" />
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={TelegramIcon} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.INSTAGRAM != "" ? appDetails?.INSTAGRAM : "#"
                  }
                  target={appDetails?.INSTAGRAM != "" ? "_blank" : ""}
                >
                  <img src={InstaIcon} alt="Instagram Icon" />
                </a>
              </li>

              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={Facebook} alt="Email Icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="FooterLogoContent">
          <div className="FooterleftPart">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            </a>
            <p>
              A multi-award winning crypto casino. With a player-centric
              approach, Goldsplay is able to satisfy millions of gamblers across
              the globe. Goldsplay has its priority set on its community,
              ensuring an everlasting and endlessly entertaining gambling
              experience.
            </p>
            <p>
              Your use of and access to Goldsplay signifies that you fully
              understand and agree to be legally bound by the contents of our
              Terms of Service and Responsible Gaming Policy
            </p>
            <p>
              Crypto trading is not gambling by definition, therefore it is not
              covered by our gaming license.
            </p>
          </div>
        </div>

        <div className="copyright">
          <p>Copyright ©2025 Fenice Tech Ltd. ALL RIGHTS RESERVED.</p>
        </div>
      </footer>

      <BottomNav />
    </>
  );
};

export default BeforeLoginFooter;
